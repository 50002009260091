import Modal from "@Atom/Modal";
import { fileBaseUrl } from "@Config/index";
import ClassesDesc from "@Molecule/ClassesDesc";
import FilePreview from "@Molecule/FilePreview";
import MaterialDesc from "@Molecule/MaterialDesc";
import ModalPopUpQuiz from "@Molecule/ModalPopUpQuiz";
import PreTestHeader from "@Molecule/PreTestHeader";
import UploadFileSection from "@Molecule/UploadFileSection";
import { postCompletionDate } from "@Services/userSheet";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import QuizModal from "../QuizModal";
import Styles from "./styles.module.scss";
import FileUploadSuccess from "@Molecule/FileUploadSuccessModal";
import ThumbnailClassVideo from "@Molecule/ThumbnailVideoClass";
import PreTestWarningInformation from "@Molecule/PreTestWarningInformation";
import { translateError } from "@Helpers/translateError";
import NextPrevButton from "@Molecule/NextPrevButton";
import useQuery from "@Hooks/useQuery";
import { decryptStorageData } from "@Helpers/encryptDecrypt";
import Images from "@Theme/Images";
import Icon from "@Atom/Icon";
import Button from "@Atom/Button";
import ModalResultScore from "@Molecule/ModalScore";
import { userCourseStore } from "store";
import SingleInputModal from "@Molecule/SingleInputModal";
import { isValidURL } from "@Helpers/isValidUrl";

export default function Material({ data }) {
  //
  const [isOpenModalScore, setIsOpenModalScore] = useState(false);
  const [isOpenModalReview, setIsOpenModalReview] = useState(false);
  //
  const { materialCode } = useParams();
  const materialStr = materialCode.split("-")[1];
  const chapterStr = materialCode.split("-")[0];
  const chapterNumber = Number(chapterStr.split("chapter")[1]);
  const materialNumber = Number(materialStr?.split("material")[1]);
  const materialData = useMemo(() => {
    return data.sessions[chapterNumber - 1]["materials"][materialNumber - 1];
  }, [chapterNumber, data.sessions, materialNumber]);
  const chapterData = useMemo(() => {
    return data.sessions[chapterNumber - 1];
  }, [chapterNumber, data.sessions]);
  const [openQuizModal, setOpenQuizModal] = useState(false);
  const navigate = useNavigate();
  const [openPopup, setOpenPopup] = useState(false);
  const [popupAnswer, setPopupAnswer] = useState("");
  const user = decryptStorageData("user", localStorage);
  const [finishUploadFile, setFinishUploadFile] = useState(false);
  const [errorUploadFile, setErrorUploadFile] = useState(false);
  const query = useQuery();
  const title = query.get("t");
  const [openDisableSession, setOpenDisableSession] = useState(false);
  const {
    postAnswerAST,
    postAnswerPopupQuiz,
    setFinishMatter,
    postAnswerTPM,
    deleteAnswerTPM,
  } = userCourseStore();
  const [openInputLinkTPM, setOpenInputLinkTPM] = useState(false);
  const [errorInputLinkTPM, setErrorInputLinkTPM] = useState("");

  useEffect(() => {
    setOpenQuizModal(false);
  }, [materialData]);

  const handleSubmitQuiz = async (answers) => {
    try {
      await postAnswerAST(
        data._id,
        chapterData.sessionID,
        materialNumber,
        answers
      );
      setIsOpenModalReview(true);
    } catch (error) {}
  };

  const handleSubmitPopup = async () => {
    const dataToSend = {
      answer: popupAnswer === "Benar",
    };
    try {
      const res = await postAnswerPopupQuiz(
        data._id,
        chapterData.sessionID,
        materialNumber,
        dataToSend
      );
      if (res.status) {
        setOpenQuizModal(false);
      }
    } catch (error) {}
  };

  const handleSubmitTpm = async (e) => {
    const file = e.target.files[0];
    const maxSize = 5 * 1024 * 1024;
    console.log(file);

    if (file && file.size < maxSize) {
      const dataToSend = {
        file: file,
        answer: {
          type: "FILE",
        },
      };
      try {
        await postAnswerTPM(
          data._id,
          chapterData.sessionID,
          materialNumber,
          dataToSend
        );

        setFinishUploadFile(true);
      } catch (error) {
        // setErrorUploadFile(translateError(error.response.data.error));
        setErrorUploadFile("error");
        console.log(error.response);
      }
    } else {
      setErrorUploadFile(
        "File terlalu besar. Batas maksimum untuk unggahan adalah 5 MB. Harap periksa ukuran file Anda dan coba lagi."
      );
    }
  };

  const handleSubmitLinkTpm = async (value) => {
    if (isValidURL(value)) {
      const dataToSend = {
        answer: {
          type: "URL",
          value: value,
        },
      };
      try {
        const res = await postAnswerTPM(
          data._id,
          chapterData.sessionID,
          materialNumber,
          dataToSend
        );
        if (res.statusCode === 200) {
          setOpenInputLinkTPM(false);
          setFinishUploadFile(true);
        }
      } catch (error) {
        setErrorInputLinkTPM(error.response.data.message);
      }
    } else {
      setErrorInputLinkTPM("Link tidak ditemukan");
    }
  };

  const handleSubmitMatter = async () => {
    try {
      const res = await setFinishMatter(
        data._id,
        chapterData.sessionID,
        materialNumber
      );
      if (res.status) {
        navigate("", { replace: true });
      }
    } catch (error) {}
  };

  const handleDeleteTPM = async (value) => {
    try {
      const res = await deleteAnswerTPM(
        data._id,
        chapterData.sessionID,
        materialNumber,
        value
      );
    } catch (error) {}
  };

  useEffect(() => {
    if (
      materialNumber !== 1 &&
      data.sessions[chapterNumber - 1].materials[materialNumber - 2]
        ?.isComplete &&
      !data.sessions[chapterNumber - 1].materials[materialNumber - 1]
        ?.isComplete
    ) {
      if (
        materialData?.content?.type === "DOCUMENT" &&
        materialData.type === "MATERIAL" &&
        materialData?.content?.type !== "VIDEO"
      ) {
        handleSubmitMatter();
      }
    }
    if (
      materialNumber === 1 &&
      !data.sessions[chapterNumber - 1].materials[materialNumber - 1]
        ?.isComplete
    ) {
      if (
        materialData?.content?.type === "DOCUMENT" &&
        materialData.type === "MATERIAL" &&
        materialData?.content?.type !== "VIDEO"
      ) {
        handleSubmitMatter();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const chapterBeforeComplete = useMemo(() => {
    if (materialNumber === 1) return true;
    return data.sessions[chapterNumber - 1]["materials"][materialNumber - 2]
      ?.isCompleted;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chapterNumber, materialNumber]);

  const nextChapterData = useMemo(() => {
    // if (materialNumber === 1) return true;
    return data.sessions[chapterNumber - 1]["materials"][materialNumber];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chapterNumber, materialNumber]);

  const handleNextButton = () => {
    if (materialNumber === chapterData.materials.length) {
      navigate(
        `/classes/learn/${data._id}/chapter${
          chapterNumber + 1
        }-material1?t=${title}`
      );
    } else {
      navigate(
        `/classes/learn/${data._id}/chapter${chapterNumber}-material${
          materialNumber + 1
        }?t=${title}`
      );
    }
  };

  const handlePrevButton = () => {
    if (materialNumber === 1) {
      if (chapterNumber === 1) {
        navigate(`/classes/learn/${data._id}/rules?t=${title}`);
      } else {
        navigate(
          `/classes/learn/${data._id}/chapter${chapterNumber - 1}-material${
            data.sessions[chapterNumber - 2].materials.length
          }?t=${title}`
        );
      }
    } else {
      navigate(
        `/classes/learn/${data._id}/chapter${chapterNumber}-material${
          materialNumber - 1
        }?t=${title}`
      );
    }
  };

  const nextButtonText = useMemo(() => {
    if (materialNumber === chapterData.materials.length) {
      if (chapterNumber === data.sessions.length) {
        return "";
      }
      return `Lanjut Ke Bagian ${chapterNumber + 1}`;
    } else {
      if (nextChapterData?.type === "MATERIAL") {
        return "Materi Selanjutnya";
      } else {
        return "Lanjut Kerjakan Asesmen";
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materialNumber, chapterNumber]);

  const finishSessionVideo = () => {
    const payload = { sessionNum: chapterNumber };
    try {
      postCompletionDate(payload, data._id);
    } catch (error) {
      console.log(error);
    }
  };

  const warningTextAST = useMemo(() => {
    if (!chapterBeforeComplete) {
      return {
        head: "Assesmen Teori Belum Dapat Dikerjakan",
        desc: "Silahkan selesaikan materi sebelumya terlebih dahulu agar Anda dapat mengerjakan kuis ini.",
      };
    }
    if (materialData.status === "filled") {
      if (materialData.trial === 1) {
        return {
          head: "Menunggu Nilai Asesmen",
          desc: "Nilai asesmen Anda masih dalam proses pemeriksaan oleh guru, mohon tunggu dan periksa nilai asesmen secara berkala",
        };
      } else {
        return {
          head: "Menunggu Penilaian",
          desc: "Nilai remidi Anda masih dalam proses pemeriksaan oleh guru, mohon tunggu dan periksa secara berkala",
        };
      }
    }
    if (materialData.status === "graded") {
      if (materialData.materialScore < 70) {
        return {
          head: "Nilai asesmen dibawah rata-rata",
          desc: "Karna nilai Anda masih dibawah ketentuan minimum, silakan lakukan remidi dengan maksimal 3 kali kesempatan",
        };
      }
    }
  }, [chapterBeforeComplete, materialData]);

  useEffect(() => {
    setPopupAnswer("");
  }, [chapterNumber, materialNumber]);

  useEffect(() => {
    const progress =
      data.sessions[chapterNumber - 1]["materials"][
        data.sessions[chapterNumber - 1]["materials"].length - 1
      ];
    if (
      chapterNumber - 1 ||
      !data.sessions[chapterNumber - 1]["materials"].completionDate
    ) {
      if (progress?.isComplete) {
        finishSessionVideo();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chapterNumber, materialNumber, data.sessions]);

  const quizButtonText = useMemo(() => {
    if (materialData.status === "empty") {
      return "Mulai Kuis";
    }
    if (materialData?.materialScore < 70) {
      if (materialData.status === "filled") {
        if (materialData.trials > 1) {
          return "Periksa Nilai Remidi";
        } else {
          return "Periksa Nilai Asesmen";
        }
      } else {
        return "Lakukan Remidi Sekarang";
      }
    } else {
      if (materialData.status === "filled") {
        return "Periksa Nilai Asesmen";
      } else {
        return "Lihat Pembahasan Jawaban";
      }
    }
  }, [materialData]);

  const handleClickButtonQuiz = () => {
    if (materialData.status === "empty") {
      setOpenQuizModal(true);
    }
    if (materialData?.materialScore < 70) {
      if (materialData.status === "filled") {
        setIsOpenModalScore(true);
      } else if (materialData.trial <= 4) {
        setOpenQuizModal(true);
      }
    } else {
      // review jawaban
      navigate("/classes/score-review", { state: materialData });
      // setIsOpenModalScore(true);
    }
  };
  // console.log(materialData);
  const handleOpenPopup = (value) => {
    if (materialData?.popUpQuiz) {
      setOpenPopup(true);
    } else {
      handleSubmitMatter();
    }
  };

  // console.log("sskor nya", materialData.materialScore);
  const modalDisableSession = useMemo(() => {
    return (
      <Modal isOpen={openDisableSession}>
        <div className={Styles.disabledOpenSession}>
          <div className={Styles.modalBody}>
            <p>Sesi Belum Dimulai</p>
            <img src={Images.SESSION_DISABLE} alt="" />
            <p>
              {data.platform === "WEBINAR"
                ? "Sesi belum bisa dibuka, silakan akses 1 jam sebelum pelatihan dimulai (redeem dan verifikasi wajah)"
                : "Sesi belum bisa dibuka, silakan akses di hari berikutnya (redeem dan verifikasi wajah)"}
            </p>
          </div>
          <div className={Styles.modalAction}>
            <Button
              text={"Tutup"}
              onClick={() => setOpenDisableSession(false)}
            />
          </div>
        </div>
      </Modal>
    );
  }, [openDisableSession, data]);

  const handleCloseScore = () => {
    setOpenQuizModal(false);
    setIsOpenModalScore(false);
    setIsOpenModalReview(false);
  };

  switch (materialData?.materialType) {
    case "MATERIAL":
      return (
        <div className={Styles.container}>
          {data.platform === "WEBINAR" && (
            <div className={Styles.meetUrl}>
              <p>Link Zoom Meeting</p>
              <div>
                <img src={Images.PC_LAYOUT} alt="" />
                <p
                  onClick={() =>
                    window.open(data?.sessions[chapterNumber - 1]?.meetURL)
                  }
                  className={Styles.link}
                >
                  {data?.sessions[chapterNumber - 1]?.meetURL}
                </p>

                <div>
                  <Icon
                    icon={"copy"}
                    size={24}
                    color={"#9360A8"}
                    onClick={() =>
                      navigator.clipboard.writeText(
                        data.sessions[chapterNumber - 1].meetURL
                      )
                    }
                  />
                </div>
              </div>
            </div>
          )}
          <div className={Styles.titleWrapper}>
            <p>{materialData?.title || "MATERI"}</p>
          </div>
          <div className={Styles.filePreviewSection}>
            {chapterBeforeComplete && materialData.content.url ? (
              <FilePreview
                fileUrl={
                  materialData.content.url.includes("https")
                    ? materialData.content.url
                    : fileBaseUrl + materialData.content.url
                }
                type={materialData.content.type}
                setOpenPopup={(value) => handleOpenPopup(value)}
                chapterNumber={chapterNumber}
              />
            ) : (
              <ThumbnailClassVideo onClick={() => navigate(-1)} />
            )}
          </div>
          <NextPrevButton
            prevText={"Sebelumnya"}
            handleNext={handleNextButton}
            nextText={nextButtonText}
            // disabledNext={!nextButtonDisabled()}
            handlePrev={handlePrevButton}
          />
          <div className={Styles.descSection}>
            <MaterialDesc
              desc={materialData.content.description || "-"}
              withAdditional={!!materialData?.additional?.uri}
              additional={materialData?.additional?.uri}
            />
          </div>
          <ModalPopUpQuiz
            isCorrect={popupAnswer === "Benar"}
            isWrong={popupAnswer === "Salah"}
            onClickIsCorrect={() => setPopupAnswer("Benar")}
            onClickIsWrong={() => setPopupAnswer("Salah")}
            isOpen={openPopup}
            question={materialData?.popUpQuiz?.question}
            answerQuestion={materialData?.popUpQuiz?.isCorrect === "true"}
            submitQuiz={handleSubmitPopup}
            onCloseQuiz={() => setOpenPopup(false)}
          />
        </div>
      );
    case "AST":
      return (
        <div>
          {((materialData.status !== "empty" &&
            materialData.materialScore < 70) ||
            !chapterBeforeComplete) && (
            <PreTestWarningInformation
              headerText={warningTextAST.head}
              description={warningTextAST.desc}
            />
          )}

          <div className={Styles.container}>
            <div className={Styles.titleWrapper}>
              <p>{materialData?.title || "KUIS"}</p>
            </div>
            <div className={Styles.fileSection}>
              <PreTestHeader
                headerPreTest="Asesmen Sumatif Teori"
                isScore={
                  materialData.materialScore === -1
                    ? false
                    : `${materialData.materialScore}`
                }
                score={
                  materialData.materialScore === -1
                    ? 0
                    : `${materialData.materialScore}`
                }
                type={"quiz"}
                headerDecription="Silakan mengerjakan asesmen berikut secara teliti dan dengan sebaik-baiknya. Berikut ini adalah ketentuannya:"
                listDescription={[
                  "Minimal hasil nilai saat mengerjakan asesmen adalah 70",
                  "Jika tidak memenuhi nilai minimum, silakan mengerjakannya ulang (remidi)",
                  " ⁠Nilai maksimal yang ditampilkan setelah mengerjakan ulang (remidi) adalah 70",
                  "Remidi hanya diberikan 3x kesempatan, nilai yang diambil adalah nilai tertinggi dari setiap percobaan",
                ]}
                textButton={quizButtonText}
                noModalConfirm
                modalTitle={
                  materialData?.materialScore === -1
                    ? "Kerjakan Remidi Asesmen Teori Sekarang?"
                    : "Kerjakan Asesmen Teori Sekarang?"
                }
                onClickButtonStart={handleClickButtonQuiz}
                modalDescription={
                  materialData?.materialScore !== -1
                    ? `Apakah Anda yakin ingin mengulang Kuis? skor kuis Anda sekarang adalah
                      <span style="color: #9360A8;font-size:16px;font-weight:600;">
                        ${materialData?.materialScore}
                      </span>
                        nilai selanjutnya yang akan ditampilkan adalah nilai terbesar yang Anda dapatkan`
                    : "Setelah kamu mulai asesmen, kamu harus menyelesaikannya segera karena tidak bisa dilanjutkan nanti."
                }
                isLockedQuiz={!chapterBeforeComplete}
                textScore={"Skor Akhir Kuis"}
                trials={materialData.trial}
              />
              <NextPrevButton
                prevText={"Sebelumnya"}
                handleNext={handleNextButton}
                nextText={nextButtonText}
                // disabledNext={!nextButtonDisabled()}
                handlePrev={handlePrevButton}
              />
            </div>
            {/* <div className={Styles.descSection}>
              <ClassesDesc data={data} />
            </div> */}
            <Modal isOpen={openQuizModal}>
              <QuizModal
                dataToMap={materialData.quizzes}
                handleSubmit={(answer) => handleSubmitQuiz(answer)}
              />
            </Modal>
            <ModalResultScore
              isOpen={isOpenModalScore}
              handleBack={handleCloseScore}
              openModalQuiz={() => {
                setOpenQuizModal(true);
                setIsOpenModalReview(false);
                setIsOpenModalScore(false);
              }}
              score={materialData.materialScore}
              type={"kuis"}
              reviewed={materialData.status === "filled"}
            />

            <ModalResultScore
              isOpen={isOpenModalReview}
              handleBack={handleCloseScore}
              openModalQuiz={() => {
                setOpenQuizModal(true);
                setIsOpenModalReview(false);
                setIsOpenModalScore(false);
              }}
              score={materialData.materialScore}
              type={"kuis"}
              reviewed={materialData.status === "filled"}
              desc={
                materialData.materialScore === -1
                  ? "Asesmen kamu telah berhasil disimpan. Harap tunggu hingga guru kelas memeriksa jawabanmu, nantinya kamu bisa melihat nilaimu nanti di halaman asesmen."
                  : ""
              }
              headtext={
                materialData.materialScore === -1 ? "Asesmen Selesai" : ""
              }
            />
          </div>
          {modalDisableSession}
        </div>
      );
    default:
      return (
        <div className={Styles.container}>
          <div className={Styles.titleWrapper}>
            <p>Asesmen Proyek</p>
          </div>
          <div className={Styles.filePreviewSection}>
            <FilePreview
              fileUrl={
                materialData.content.url.includes("https")
                  ? materialData.content.url
                  : fileBaseUrl + materialData.content.url
              }
              type={materialData.content.type}
            />
          </div>
          <NextPrevButton
            prevText={"Sebelumnya"}
            handleNext={handleNextButton}
            nextText={nextButtonText}
            // disabledNext={!nextButtonDisabled()}
            handlePrev={handlePrevButton}
          />
          <div className={Styles.descSection}>
            <MaterialDesc
              withAdditional={false}
              desc={materialData.content.description}
            />
            {materialData?.answers?.map((el, idx) => (
              <div key={idx} className={Styles.fileUploaded}>
                <UploadFileSection
                  file={fileBaseUrl + el.value}
                  fileName={el.value}
                  handleEdit={true}
                  withLabel={idx === 0}
                  type={el.type}
                  handleChangeFile={handleDeleteTPM}
                />
              </div>
            ))}
            <UploadFileSection
              file={
                materialData.content.answer
                  ? fileBaseUrl + materialData.content.answer
                  : false
              }
              fileName={materialData.content.answer}
              handleChangeFile={handleSubmitTpm}
              handleInputLink={() => setOpenInputLinkTPM(true)}
            />
          </div>
          <SingleInputModal
            handleClose={() => setOpenInputLinkTPM(false)}
            isOpen={openInputLinkTPM}
            headTitle={"Tulis Link Video"}
            error={errorInputLinkTPM}
            handleSubmit={handleSubmitLinkTpm}
          />
          {/* {materialData.score.feedback && (
          <div className={Styles.scoreSection}>
            <div className={Styles.sectionTitle}>
              <p>Hasil Tugas Praktek Mandiri</p>
            </div>
            <div className={Styles.sectionBody}>
              <div className={Styles.wrapScoreResult}>
                <div
                  className={Styles.scoreText}
                  score={materialData.score.overall}
                ></div>
              </div>
              <div>
                <p>Feedback</p>
                <p>{materialData.score.feedback}</p>
              </div>
            </div>
          </div>
        )} */}
          <Modal isOpen={finishUploadFile}>
            <FileUploadSuccess
              text={"File Tugas Praktek Mandiri berhasil diunggah"}
              onClose={() => setFinishUploadFile(false)}
            />
          </Modal>
          <Modal isOpen={errorUploadFile}>
            <FileUploadSuccess
              text={errorUploadFile}
              onClose={() => setErrorUploadFile(false)}
              status="reject"
              headText="File Gagal Diunggah"
            />
          </Modal>
          {modalDisableSession}
        </div>
      );
  }
}
