import React, { useCallback, useEffect, useState } from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "@Atom/Button";
import AdminDetailClass from "./ClassDetail";
import StudentListAdmin from "./StudentList";
import useQuery from "@Hooks/useQuery";
import { getCourse } from "@Services/course";
import TaskAssessment from "./TaskAssessment";

export default function TeacherClassDetail() {
  const navigate = useNavigate();
  const [tab, setTab] = useState(1);
  const query = useQuery();
  const tabQuery = query.get("t") || 1;
  const { pathname } = useLocation();
  const [dataClass, setDataClass] = useState(null);
  const { id } = useParams();

  const fetchDataClass = async () => {
    try {
      const { course } = await getCourse(id);
      setDataClass(course);
    } catch (error) {}
  };

  useEffect(() => {
    fetchDataClass();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTab(+tabQuery);
  }, [tabQuery]);

  const renderComponent = useCallback(() => {
    switch (tab) {
      case 1:
        return <AdminDetailClass data={dataClass} />;
      case 2:
        return <StudentListAdmin />;
      case 3:
        return <TaskAssessment data={dataClass} />;
      default:
        break;
    }
  }, [dataClass, tab]);

  return (
    <div className={Styles.container}>
      <div className={Styles.breadcrumbsWrapper}>
        <div
          className={Styles.backIcon}
          onClick={() =>
            navigate(`${pathname.split("/").slice(0, 3).join("/")}`)
          }
        >
          <Icon icon={"chevron-left"} size={16} />
        </div>
        <div className={Styles.breadcrumb}>
          <p>Daftar Kelas</p>
          <Icon icon={"chevron-right"} size={16} color={"#3E4856"} />
          <p>{dataClass?.subjectName}</p>
        </div>
      </div>
      <div className={Styles.tabHeaderWrapper}>
        <Button
          variant="tab"
          text={"Detail Kelas"}
          isActive={tab === 1}
          onClick={() => setTab(1)}
        />
        <Button
          variant="tab"
          text={"Daftar Murid"}
          isActive={tab === 2}
          onClick={() => setTab(2)}
        />
        <Button
          variant="tab"
          text={"Penilaian Tugas"}
          isActive={tab === 3}
          onClick={() => setTab(3)}
        />
      </div>
      {renderComponent()}
    </div>
  );
}
