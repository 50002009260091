import React from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import RightArrow from "@Assets/Icon/arrow-circle-right-outlined.svg";

export default function TaskAssessment({ data }) {
  console.log(data);
  return (
    <div className={Styles.container}>
      <div className={Styles.cardWrapper}>
        {data.sessions.map((el, idx) => (
          <div className={Styles.sessionCard} key={idx}>
            <div className={Styles.cardIcon}>
              <Icon icon={"book2"} size={32} color={"#FFF"} />
            </div>
            <div className={Styles.cardText}>
              <p>
                Bagian {el.sessionNum} - {el.title}
              </p>
              <div className={Styles.cardDetail}>
                <p>
                  Total Asesmen Diselesaikan Murid : <span>80/160</span>
                </p>
                <p className={Styles.divider}>|</p>
                <p>
                  Total Asesmen Dinilai : <span>40/80</span>
                </p>
              </div>
            </div>
            <div className={Styles.cardAction}>
              <img alt="" src={RightArrow} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
